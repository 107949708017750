schedule-cell-dimension.e-schedule .e-vertical-view .e-time-cells-wrap table td, 
.schedule-cell-dimension.e-schedule .e-vertical-view .e-work-cells, 
.schedule-cell-dimension.e-schedule .e-vertical-view .e-time-slots{
  height: 25px !important;
}


.schedule-cell-dimension .e-subject {
  font-size: 10px !important;
}

.e-appointment-details::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 8px;
  height: 8px;
  background-color: var(--color);
}
